import { Button, Icon, addToast } from '@octano/global-ui';
import React, { Fragment, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import { Badge, FormText } from 'reactstrap';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { TranslationsKeys } from '../../locales/translations';
import { AxiosResult, AxiosResultDefaultError } from '../../api/request';

export type MultipleFileInputProps = {
  name: string;
  label?: string;
  accept?: string;
  rules?: RegisterOptions;
  control: any;
  uploadTempFile: (
    file: File,
  ) => Promise<
    AxiosResult<
      {
        id: string;
      },
      AxiosResultDefaultError
    >
  >;
  deleteTempFile: (
    id: string,
  ) => Promise<AxiosResult<any, AxiosResultDefaultError>>;
  onLoadingChange?: (isLoading: boolean) => void;
};

interface UploadedFile {
  id: string;
  name: string;
}

const styles = {
  label: {
    textAlign: 'left' as const,
    font: 'normal normal normal 16px/30px Source Sans Pro',
    color: '#1e1e1e',
    marginBottom: '8px',
  },
  bigButtonContainer: {
    marginBottom: '1rem',
  },
  filesBadgesContainer: {
    display: 'flex' as const,
    flexWrap: 'wrap' as const,
    gap: '8px',
  },
  fileBadge: {
    display: 'inline-flex' as const,
    alignItems: 'center' as const,
    paddingRight: '8px',
    cursor: 'default',
  },
  fileName: {
    marginRight: '4px',
    maxWidth: '120px',
    whiteSpace: 'nowrap' as const,
    overflow: 'hidden' as const,
    textOverflow: 'ellipsis' as const,
  },
  removeIcon: {
    marginLeft: '4px',
    cursor: 'pointer',
    display: 'inline-flex' as const,
    alignItems: 'center' as const,
  },
};

const MultipleFileInput: React.FC<MultipleFileInputProps> = ({
  name,
  label = name,
  rules,
  accept,
  control,
  uploadTempFile,
  deleteTempFile,
  onLoadingChange,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(TranslationsKeys.PETITIONS);

  const text = useMemo(() => {
    const prefix = 'modal';
    return {
      message: {
        error: t(`${prefix}.newPetition.message.error`),
      },
      file: {
        deleted: t(`${prefix}.newPetition.file.deleted`),
        button: t(`${prefix}.newPetition.file.button`),
      },
    };
  }, [t]);

  // Llamar a la API para subir el archivo y obtener un id
  const uploadFile = async (file: File): Promise<string> => {
    setLoading(true);
    onLoadingChange && onLoadingChange(true);
    const { error, data, status } = await uploadTempFile(file);
    setLoading(false);
    onLoadingChange && onLoadingChange(false);

    if (data) {
      return data.id;
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: error.error.message,
      });
      throw new Error(error.error.message);
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: text.message.error,
      });
      throw new Error(text.message.error);
    }
  };

  // Llamar a la API para remover el archivo temporal
  const removeFile = async (
    index: number,
    currentFiles: UploadedFile[],
    onChange: (files: UploadedFile[]) => void,
  ) => {
    const updatedFiles = currentFiles.filter(
      (_: UploadedFile, i: number) => i !== index,
    );

    setLoading(true);
    const { error, status } = await deleteTempFile(currentFiles[index].id);
    setLoading(false);

    if (status === 200) {
      addToast({
        icon: 'success',
        color: 'success',
        text: text.file.deleted,
      });
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: error.error.message,
      });
      return;
    }
    onChange(updatedFiles);
  };

  return (
    <>
      {label && (
        <label style={styles.label} htmlFor={name}>
          {label}
        </label>
      )}

      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState }) => (
          <Dropzone
            accept={accept}
            multiple={true}
            noClick
            onDrop={async (acceptedFiles) => {
              const currentFiles: UploadedFile[] = Array.isArray(field.value)
                ? [...field.value]
                : [];

              for (const file of acceptedFiles) {
                try {
                  const fileId = await uploadFile(file);
                  currentFiles.push({ id: fileId, name: file.name });
                } catch (error) {
                  addToast({
                    icon: 'error',
                    color: 'danger',
                    text: error.message,
                  });
                  return;
                }
              }

              field.onChange(currentFiles);
            }}
          >
            {({ getRootProps, getInputProps, open }) => (
              <Fragment>
                <div {...getRootProps()} style={styles.bigButtonContainer}>
                  <input {...getInputProps()} />
                  <Button
                    text={text.file.button}
                    color="primary"
                    outlined
                    size="md"
                    onClick={open}
                    loading={loading}
                    icon="export"
                    fullwidth
                  />
                </div>

                {/* Lista de archivos subidos */}
                <div style={styles.filesBadgesContainer}>
                  {Array.isArray(field.value) &&
                    field.value.map((file: UploadedFile, index: number) => (
                      <Badge
                        key={index}
                        className="status-badge fs-14 fw-400 py-2 px-3 bg-tertiary border border-secondary"
                      >
                        <span style={styles.fileName}>{file.name}</span>
                        <span
                          role="button"
                          style={{
                            ...styles.removeIcon,
                            cursor: loading ? 'not-allowed' : 'pointer',
                          }}
                          onClick={() =>
                            !loading &&
                            removeFile(index, field.value, field.onChange)
                          }
                        >
                          <br />
                          <Icon name="error" size="16px" />
                        </span>
                      </Badge>
                    ))}
                </div>

                {fieldState.error?.message && (
                  <FormText className="g-input-error" color="danger">
                    {fieldState.error.message}
                  </FormText>
                )}
              </Fragment>
            )}
          </Dropzone>
        )}
      />
    </>
  );
};

export default MultipleFileInput;
