import { SelectOptionType } from '@octano/global-ui';
import { SectionType } from './sectionType';

export interface PetitionInf {
  folio: number;
  createdAt: Date;
  studyPlanVersionName: string;
  typeId: number;
  typeName: string;
  status: PetitionStatus;
  paymentStatus: PetitionPaymentStatus | null;
  paymentRequired: boolean;
  title: string;
}

export enum PetitionStatus {
  Received = 'recibida',
  InProcess = 'en_proceso',
  Waiting = 'en_espera',
  Resolved = 'resuelta',
  Approved = 'aprobada',
  Rejected = 'rechazada',
}

export enum PetitionPaymentStatus {
  Paid = 'paid',
  Pending = 'pending',
}

export interface CreatePetition {
  studyPlanEnrollmentId: number | string;
  phone: string;
  email: string;
  typeId: number | string;
  title: string;
  description: string;
  docIds: number[];
  replaceEvaluationGrade?: string;
  studentTestGradeId?: number;
  studentFinalGradeId?: number;
  sectionId?: number;
  finalGrade?: number;
}

export interface PetitionData {
  studyPlanEnrollment: SelectOptionType;

  cellPhone: string;

  email: string;

  type: SelectOptionType;

  title: string;

  description: string;

  resolved?: string;

  doc: File[];

  docs: {
    id: number;
    name: string;
  }[];

  replaceEvaluationGrade?: {
    value: number;
    label: string;
  };

  studentTestGradeId?: number;

  studentFinalGradeId?: number;

  section?: {
    value: number;
    label: string;
  };

  grade?: string;

  course?: {
    value: number;
    label: string;
  };
}

export interface CreatePetitionData {
  email: string;

  cellPhone?: string;

  types: {
    id: number;
    name: string;
  }[];

  replaceEvaluationGrades: {
    studyPlanEnrollmentId: number;
    grades: {
      studentTestGradeId?: number;
      studentFinalGradeId?: number;
      courseName: string;
      sectionId: string;
      evaluationName: string;
      evaluationDate: string;
    }[];
  }[];

  replaceCourseGrades: {
    section: SectionType;
    finalGrade: number;
  }[];

  proficiencyCourses: {
    studyPlanEnrollmentId: number;
    courses: {
      courseId: number;
      courseName: string;
    }[];
  }[];
}

export interface ViewPetition {
  id: number;

  studyPlanEnrollmentId: number;

  phone: string;

  email: string;

  type: {
    id: number;
    name: string;
  };

  title: string;

  description: string;

  resolved?: {
    id: number;
    comment: string;
  };

  docs?: {
    name: string;
    url: string;
  }[];

  docResponse?: {
    name: string;
    url: string;
  };

  voucher?: {
    name: string;
    url: string;
  };
}

export enum PetitionTypes {
  ACADEMIC = 'ACD',
  OTHER = 'OTH',
  PAYMENT = 'PAY',
  PROFICIENCY = 'PRO',
  REPLACE_COURSE_GRADE = 'RCG',
  REPLACE_EVALUATION_GRADE = 'REG',
  VALIDATION = 'VAL',
}

export interface PetitionPaymentDetails {
  paymentStatus: PetitionPaymentStatus;
  voucherBoxItems: { label: string; value: string }[];
}
