import { Button, Icon } from '@octano/global-ui';
import { Card, Col, Row, Badge } from 'reactstrap';
import { useCallback } from 'react';

import { ViewPetition } from '../../../../types/petitions';

export interface PetitionFileDownloadProps {
  file?: ViewPetition['docResponse'];
  files?: ViewPetition['docs'];
  text: string;
  label: string;
  voucher?: ViewPetition['voucher'];
  voucherLabel?: string;
}

export default function PetitionFileDownload({
  file,
  files,
  label,
  text,
  voucher,
  voucherLabel,
}: PetitionFileDownloadProps) {
  const downloadFile = useCallback(
    (fileItem: { name: string; url: string }) => {
      const link = document.createElement('a');
      link.download = fileItem.name;
      link.href = fileItem.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [],
  );

  const renderFileInfo = () => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
        {files!.map((f, idx) => (
          <Badge
            key={idx}
            className="status-badge fs-14 fw-400 py-2 px-3 bg-tertiary border border-secondary"
            style={{ cursor: 'pointer' }}
            onClick={() => downloadFile(f)}
          >
            {f.name} <Icon name="download" size="16px" />
          </Badge>
        ))}
      </div>
    );
  };

  const renderVoucherInfo = () => {
    if (voucher) {
      return (
        <>
          <span>
            <Icon name="file_img" size="25px" color="primary" />
          </span>
          <span className="g-file-input-name">{voucher.name}</span>
        </>
      );
    }
    return null;
  };

  const renderVoucherDownloadButton = () => {
    if (voucher) {
      return (
        <Button
          color="primary"
          outlined
          size="md"
          disabled={!voucher}
          onClick={() => voucher && downloadFile(voucher)}
          icon="download"
        />
      );
    }
    return null;
  };

  return (
    <Card>
      {(files?.length || voucher) && (
        <Row className="mb-2">
          <Col xs={12} sm={voucher ? 6 : 12}>
            <label className="g-file-input-label text-left">{label}</label>
          </Col>
          {voucher && (
            <Col xs={12} sm={6}>
              <label className="g-file-input-label text-left">
                {voucherLabel}
              </label>
            </Col>
          )}
        </Row>
      )}

      {voucher ? (
        <Row>
          <Col xs={12} sm={6}>
            {files && files.length > 0 && (
              <div className="p-3">{renderFileInfo()}</div>
            )}
          </Col>
          <Col xs={12} sm={3}>
            <div className="g-file-input-container">{renderVoucherInfo()}</div>
          </Col>
          <Col xs={12} sm={3}>
            <div className="g-file-input-container">
              {renderVoucherDownloadButton()}
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          {files && files.length > 0 && (
            <div className="p-3">{renderFileInfo()}</div>
          )}
        </Row>
      )}

      {!!file && (
        <Row className="d-flex flex-row align-items-center">
          <Col xs={12} sm={6}>
            <div className="g-file-input-container d-flex align-items-center">
              <>
                <span>
                  <Icon name="file_img" size="25px" color="primary" />
                </span>
                <span className="g-file-input-name">{file.name}</span>
              </>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <Button
              text={text}
              color="primary"
              outlined
              size="md"
              disabled={!file}
              onClick={downloadFile.bind(null, file)}
              icon="download"
              fullwidth
            />
          </Col>
        </Row>
      )}
    </Card>
  );
}
