import { useEffect, useMemo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Col } from 'reactstrap';
import {
  Select,
  SelectOptionType,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useValidations } from '../../../../hooks/useValidations';
import { PetitionData, ViewPetition } from '../../../../types/petitions';
import PetitionFileDownload from './PetitionFileDownload';
import { TranslationsKeys } from '../../../../locales/translations';
import {
  deleteTempFile,
  uploadTempFile,
} from '../../../../api/requests/petitions';
import MultipleFileInput from '../../../../components/fileInput/MultipleFileInput';

export interface PetitionFormReplaceEvaluationGradeProps {
  careerOptions?: SelectOptionType[];
  replaceEvaluationGrades: {
    studyPlanEnrollmentId: number;
    grades: {
      studentTestGradeId?: number;
      studentFinalGradeId?: number;
      courseName: string;
      sectionId: string;
      evaluationName: string;
      evaluationDate: string;
    }[];
  }[];
  downloadFiles?: ViewPetition['docs'];
  downloadFileResponse?: ViewPetition['docResponse'];
  onlyView?: boolean;
  isResolved?: boolean;
  onLoadingChange?: (loading: boolean) => void;
}

export default function PetitionFormReplaceEvaluationGrade({
  careerOptions = [],
  replaceEvaluationGrades = [],
  onlyView = false,
  isResolved = false,
  downloadFiles,
  downloadFileResponse,
  onLoadingChange,
}: PetitionFormReplaceEvaluationGradeProps) {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);
  const maxValidation = useCallback(
    (value: number) => ({
      value,
      message: t(`modal.form.validation.max`, { value }),
    }),
    [t],
  );
  const {
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();
  const { control, setValue } = useFormContext<PetitionData>();

  const selectedStudyPlanEnrollment = useWatch({
    name: 'studyPlanEnrollment',
    control,
  });
  const replaceEvaluationGrade = useWatch<PetitionData>({
    name: 'replaceEvaluationGrade',
    control,
  });

  const grades = replaceEvaluationGrades.find(
    (entry) =>
      entry.studyPlanEnrollmentId === selectedStudyPlanEnrollment?.value,
  )?.grades;

  useEffect(() => {
    if (!replaceEvaluationGrade?.value) return;
    const isTestGrade = grades?.find(
      (grade) => grade.studentTestGradeId === replaceEvaluationGrade?.value,
    );
    const isFinalGrade = grades?.find(
      (grade) => grade.studentFinalGradeId === replaceEvaluationGrade?.value,
    );
    if (isTestGrade) {
      setValue('studentTestGradeId', replaceEvaluationGrade.value);
      setValue('title', replaceEvaluationGrade.label);
      setValue('studentFinalGradeId', undefined);
    }
    if (isFinalGrade) {
      setValue('studentFinalGradeId', replaceEvaluationGrade.value);
      setValue('title', replaceEvaluationGrade.label);
      setValue('studentTestGradeId', undefined);
    }
  }, [replaceEvaluationGrade, grades, setValue]);

  const replaceEvaluationGradeOptions = useMemo(() => {
    const selectedId =
      selectedStudyPlanEnrollment?.value ?? selectedStudyPlanEnrollment;

    const matchingGrades =
      replaceEvaluationGrades.find(
        (entry) => entry.studyPlanEnrollmentId === selectedId,
      )?.grades ?? [];

    return matchingGrades.map((grade) => ({
      value: grade.studentTestGradeId ?? grade.studentFinalGradeId ?? 0,
      label: `NR - ${grade.courseName} - ${grade.sectionId} - ${
        grade.evaluationName
      }${grade.evaluationDate ? ` - ${grade.evaluationDate}` : ''}`,
    }));
  }, [replaceEvaluationGrades, selectedStudyPlanEnrollment]);

  const text = useMemo(() => {
    const prefix = 'modal.form';
    return {
      studyPlan: t(`${prefix}.studyPlan`),
      phone: t(`${prefix}.phone`),
      email: t(`${prefix}.email`),
      title: t(`${prefix}.title`),
      reason: {
        label: t(`${prefix}.reason.label`),
        placeholder: t(`${prefix}.reason.placeholder`),
      },
      replaceEvaluationGrade: {
        label: t(`${prefix}.replaceEvaluationGrade.label`),
        placeholder: t(`${prefix}.replaceEvaluationGrade.placeholder`),
      },
      file: {
        label: t(`${prefix}.file.label`),
        button: t(`${prefix}.file.button`),
      },
      download: {
        labelResponse: t(`${prefix}.download.labelResponse`),
        label: t(`${prefix}.download.label`),
        button: t(`${prefix}.download.button`),
      },
      validation: {
        select: t(`${prefix}.validation.select`),
      },
      resolved: {
        label: t(`${prefix}.resolved.label`),
      },
    };
  }, [t]);

  return (
    <>
      <Col md={12}>
        <Select
          label={text.studyPlan}
          control={control}
          disabled={onlyView}
          options={careerOptions}
          rules={{
            required: text.validation.select,
          }}
          name="studyPlanEnrollment"
          isClearable={false}
        />
      </Col>
      <Col md={6} className="my-3">
        <TextInput
          label={text.phone}
          name="cellPhone"
          disabled={onlyView}
          control={control}
          rules={{
            validate: { validatePhone, require: validateTextNotEmpty },
          }}
        />
      </Col>
      <Col md={6} className="my-3">
        <TextInput
          label={text.email}
          name="email"
          control={control}
          disabled={onlyView}
          rules={{
            validate: { validateEmail, require: validateTextNotEmpty },
          }}
        />
      </Col>
      <Col md={12} className="my-3">
        <Select
          label={text.replaceEvaluationGrade.label}
          placeholder={text.replaceEvaluationGrade.placeholder}
          name="replaceEvaluationGrade"
          control={control}
          options={replaceEvaluationGradeOptions}
          disabled={onlyView}
          rules={{ required: 'Seleccione una nota a suplir' }}
          isClearable={false}
        />
      </Col>
      <Col md={12}>
        <TextAreaInput
          label={text.reason.label}
          name="description"
          placeholder={text.reason.placeholder}
          disabled={onlyView}
          rules={{
            validate: { require: validateTextNotEmpty },
            maxLength: maxValidation(500),
          }}
          control={control}
        />
      </Col>
      <Col md={12} className="my-4">
        {onlyView ? (
          <PetitionFileDownload
            text={text.download.button}
            label={text.download.label}
            files={downloadFiles}
          />
        ) : (
          <MultipleFileInput
            name="docs"
            label={text.file.label}
            accept="image/png, image/jpeg, application/pdf"
            control={control}
            uploadTempFile={uploadTempFile}
            deleteTempFile={deleteTempFile}
            onLoadingChange={onLoadingChange}
          />
        )}
      </Col>

      {isResolved && downloadFileResponse && (
        <Col md={12} className="my-4">
          <PetitionFileDownload
            text={text.download.button}
            label={text.download.labelResponse}
            file={downloadFileResponse}
          />
        </Col>
      )}
    </>
  );
}
